import request from "../../../../adminplat/src/utils/request";

export default ({request}) => ({
  // 获取项目列表
  ProjectList(params) {
    return request({
      url: '/prepressProject/list',
      method: 'get',
      params,
    });
  },
  // 获取印量时间稼动率性能稼动率
  getPrintVolume(query) {
    return request({
      url: '/newApp/dayInfo/printVolume',
      method: 'get',
      params: query,
    });
  },
  // 获取异常停机
 getAbnormalShutdown(query) {
    return request({
      url: '/newApp/dayInfo/abnormalShutdown',
      method: 'get',
      params: query,
    });
  },
  // 获取印刷质量质量评分
  getQualityScore(query) {
    return request({
      url: '/newApp/dayInfo/qualityScore',
      method: 'get',
      params: query,
    });
  },
  // 获取印刷质量放墨精度
  getInkAccuracy(query) {
    return request({
      url: '/newApp/dayInfo/inkAccuracy',
      method: 'get',
      params: query,
    });
  },
// 获取印刷质量水墨平衡
 getInkBalance(query) {
    return request({
      url: '/newApp/dayInfo/inkBalance',
      method: 'get',
      params: query,
    });
  },
  // 获取印刷质量质量评分详情
  getQualityScoreDetail(query) {
    return request({
      url: '/newApp/dayInfo/qualityScoreDetail',
      method: 'get',
      params: query,
    });
  },
  // 获取润版液
   getDampeningSolution(query) {
    return request({
      url: '/newApp/dayInfo/dampeningSolution',
      method: 'get',
      params: query,
    });
  },
  getMaintain(query) {
    return request({
      url: '/newApp/dayInfo/maintain',
      method: 'get',
      params: query,
    });
  },
// 获取保养点检排名
  getmaintainRanking(query) {
    return request({
      url: '/newApp/dayInfo/maintainRanking',
      method: 'get',
      params: query,
    });
  },
  // 获取维修
  getRepair(query) {
    return request({
      url: '/newApp/dayInfo/repair',
      method: 'get',
      params: query,
    });
  },
// 维修明细
  detaileListWeixiu(query) {
    return request({
      url: '/newApp/reqair/detaileList',
      method: 'get',
      params: query,
    });
  },
// 设备接口
  deviceListWeixiu(params) {
    return request({
      url: '/newApp/reqair/deviceByTeamId',
      method: 'get',
      params,
    });
  },
// 获取制版质量
   getPlateQuality(query) {
    return request({
      url: '/newApp/dayInfo/plateQuality',
      method: 'get',
      params: query,
    });
  },
// 获取日活报表
  getRoleLog(query) {
    return request({
      url: 'newApp/dayInfo/roleLog',
      method: 'get',
      params: query,
    });
  },
// 获取印量分析
  getDateDetail(query) {
    return request({
      url: 'newApp/info/date/detail',
      method: 'get',
      params: query,
    });
  },

  CompanyPreserveList(data) {
    return request({
      url: '/web/device/list',
      method: 'post',
      data,
    });
  },
  // 异常待机明细查询
  abnormalWebList(params) {
    return request({
      url: '/newApp/abnormalWeb/list',
      method: 'get',
      params,
    });
  },
  // 润版监控明细
   alarmDetailList(params) {
    return request({
      url: '/newApp/alarm/detailList',
      method: 'get',
      params,
    });
  },
  // 定期更换
getRegularList(query) {
    return request({
      url: '/newApp/dayInfo/regularList',
      method: 'get',
      params: query,
    });
  }

  // 班组
  // getClassDate(params) {
  //   return request({
  //     url: '/teamGroup/list',
  //     method: 'get',
  //     params,
  //   });
  // },
});
